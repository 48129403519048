import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import mediaqueries from "../../styles/media"

interface Props {
  topItems: string[]
  pricingItems: {
    title: string
    type: "full" | "split"
    price1: string
    price2?: string
    description1?: string
    description2?: string
  }[]
}

const PricingGrid: React.FC<Props> = ({ topItems, pricingItems }) => {
  const gridEnd = topItems.length + 1 // 1 + empty div for the corner

  return (
    <PricingHolder gridEnd={gridEnd}>
      <div />
      {topItems.map(item => (
        <WhiteLabel inVisible small key={item}>
          <p>{item}</p>
        </WhiteLabel>
      ))}
      {pricingItems.map(item => (
        <React.Fragment key={item.title}>
          <WhiteLabel
            small={(item && !item.description1) || (item && !item.description2)}
          >
            <p>{item.title}</p>
          </WhiteLabel>
          <MobileTopBar>
            <p>Mini</p>
          </MobileTopBar>
          {item.price1 && (
            <LightRedLabel
              small={
                (item && !item.description1) || (item && !item.description2)
              }
              description={item.description1}
              gridEnd={gridEnd}
              type={item.type}
            >
              <p>{item.price1}</p>
              {item.description1 && <p>* {item.description1}</p>}
            </LightRedLabel>
          )}
          <MobileTopBar>
            <p>Max</p>
          </MobileTopBar>
          {item.price2 && (
            <RedLabel
              small={
                (item && !item.description1) || (item && !item.description2)
              }
              description={item.description2}
              gridEnd={gridEnd}
              type={item.type}
            >
              <p>{item.price2}</p>
              {item.description2 && <p>* {item.description2}</p>}
            </RedLabel>
          )}
        </React.Fragment>
      ))}
    </PricingHolder>
  )
}

export default PricingGrid

const PricingHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(${(p: any) => p.gridEnd}, minmax(200px, 1fr));
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  ${mediaqueries.desktop`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
`};
`

const commonStyles = (p: any) => css`
  height: 280px;
  padding: 80px 50px 50px 50px;
  border-radius: ${p.theme.radius.normal};
  filter: ${p.theme.shadow.box};
  font-family: ${p.theme.fonts.sansSerif};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 122.19%;
  /* or 22px */
  text-align: center;
  letter-spacing: 0.05em;
  z-index: 99999;

  & p:first-of-type {
    font-weight: 600;
    margin-top: -20px;
    font-size: 24px;
  }

  @media (max-width: 66.875em) {
    margin: auto;
    min-width: 280px;
    width: 100%;
    padding: 50px;
    justify-content: space-around;

    & p:first-of-type {
      font-weight: 600;
      font-size: 22px;
      margin: 10px;
    }
  }
`

const fullStyles = (gridEnd: number) => css`
  width: 100%;
  grid-column-start: 2;
  grid-column-end: ${gridEnd + 1};
  height: 280px;
`

const WhiteLabel = styled.div`
  ${(p: any) => commonStyles(p)};
  display: flex;
  height: ${(p: any) => (p.small ? "130px" : "280px")};
  justify-content: center;
  align-items: center;
  background-color: ${(p: any) => p.theme.colors.white};

  & p {
    text-transform: uppercase;
    color: ${(p: any) => p.theme.colors.primary};
    font-weight: 600;
    font-size: 22px !important;
  }

  @media (max-width: 66.875em) {
    display: ${(p: any) => (p.inVisible ? "none" : "flex")};

    & p {
      font-size: 18px !important;
    }
  }
`

const LightRedLabel = styled.div`
  ${(p: any) => commonStyles(p)};
  display: flex;
  flex-direction: column;
  justify-content: ${(p: any) => (p.description ? "space-between" : "center")};
  align-items: center;
  background-color: ${(p: any) => p.theme.colors.lightRed};

  & p {
    color: ${(p: any) => p.theme.colors.primary};
  }

  ${(p: any) => p.type === "full" && fullStyles(p.gridEnd)};
  height: ${(p: any) => (p.small ? "130px" : "280px")};
`

const RedLabel = styled.div`
  ${(p: any) => commonStyles(p)}
  display: flex;
  flex-direction: column;
  justify-content: ${(p: any) => (p.description ? "space-between" : "center")};
  align-items: center;
  background-color: ${(p: any) => p.theme.colors.secondary};
  & p {
    color: ${(p: any) => p.theme.colors.white};
  }

  ${(p: any) => p.type === "full" && fullStyles(p.gridEnd)};
  height: ${(p: any) => (p.small ? "130px" : "280px")};
`

const MobileTopBar = styled.div`
  display: none;
  background: ${(p: any) => p.theme.colors.white};
  filter: ${(p: any) => p.theme.shadow.box};
  border-radius: ${(p: any) => p.theme.radius.normal};
  font-size: 18px;

  @media (max-width: 66.875em) {
    width: 100%;
    display: flex;
    height: 130px;
    justify-content: center;
    align-items: center;

    & p {
      font-weight: 600;
      text-transform: uppercase;
      color: ${(p: any) => p.theme.colors.primary};
    }

    &:nth-of-type(15) {
      display: none;
    }

    &:nth-of-type(17) {
      display: none;
    }

    &:nth-of-type(19) {
      display: none;
    }

    &:nth-of-type(20) {
      display: none;
    }
  }
`
