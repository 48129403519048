import React, { useState } from "react"
import { Global } from "@emotion/core"
import { globalStyles } from "../styles"
import { graphql, useStaticQuery } from "gatsby"
import PageHero from "../components/pageHero"
import Register from "../components/register"
import Footer from "../components/footer"
import Layout from "../components/layout"
import MDX from "../components/mdx"
import styled from "@emotion/styled"
import PricingGrid from "../components/pricingGrid"
import SEO from "../components/seo"
import MobileNavigation from "../components/navigationMobile"
import mediaqueries from "../styles/media"


interface Props {
  location: any
}

const siteQuery = graphql`
  query pricingQuery {
    pricing: mdx(frontmatter: { gatsbyTitle: { eq: "pricing" } }) {
      id
      frontmatter {
        title
        pricingTopColumnNames
        pricingItems {
          title
          type
          price1
          price2
          description1
          description2
        }
      }
      body
    }
  }
`

const Cennik: React.FC<Props> = ({ location }) => {
  const data = useStaticQuery(siteQuery)

  const {
    title,
    pricingTopColumnNames,
    pricingItems,
  } = data.pricing.frontmatter
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <Global styles={globalStyles} />
      <SEO title={"Cennik"} />
      <MobileNavigation isOpen={isOpen} setOpen={setOpen} location={location} />
      <PageHero title={title} location={location} />
      <Main>
        <Layout>
        <MobileSpacer />
          <PricingGrid
            topItems={pricingTopColumnNames}
            pricingItems={pricingItems}
          />
          <PricingBodyHolder>
            <MDX content={data.pricing.body} />
          </PricingBodyHolder>
        </Layout>
        <Register />
        <Footer />
      </Main>
    </>
  )
}

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -200px;
`

const PricingBodyHolder = styled.div`
  margin-left: 10px;
  line-height: 25px;
  margin-top: 100px;
  margin-bottom: 100px;

  & strong {
    color: ${(p: any) => p.theme.colors.secondary};
  }

  & a {
    color: ${(p: any) => p.theme.colors.secondary};
  }

  & p {
    margin-top: 10px;
  }
`

const MobileSpacer = styled.div`
display: none;

${mediaqueries.desktop`
display: block;
min-height: 100px;
`};
`

export default Cennik
